import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";


export default function Footer() {

	const [date, setDate] = useState(new Date());
	const options = { year: "numeric" }

	useEffect(() => {
		// refresh the data every year
		const intervalId = setInterval(() => setDate(new Date()), 31536000);

		return function () {
			clearInterval(intervalId);
		};
	});

	return (
		<>
			<footer>
				<div className="footer-top">
					<div className="container">
						<div className="row">
							<div className="col-12 col-lg-12 col-md-12 col-sm-12 order-2 text-center">
								<div className="row">
									<div className="col-12 col-lg-12 col-md-12 col-sm-12">
										<div className="footer-innner-btm">
											<div className="copyright-bx">
												<p><Link to="/mentions-legales" className="link-footer-white">Mentions légales</Link></p>
											</div>
											<div className="copyright-bx">
												<p><Link to="/politique-de-confidentialite" className="link-footer-white">Politique de confidentialité</Link></p>
											</div>
											<div className="copyright-bx">
												<p><Link to="/copyright" className="link-footer-white">©</Link> {date.toLocaleDateString("fr-FR", options)} <Link to="/" className="link-footer-white">YogShow</Link></p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}