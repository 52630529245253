import React, { useState, useEffect } from 'react';
import axios from "axios";
import config from "../../../config.json";

// Layout
import Header from '../../layout/header/header';
import Footer from '../../layout/footer/footer';

// Element
import Banner from '../../elements/banner/banner';
import SEO from '../../elements/seo/seo';

// Modal
import Modal from '../../elements/modal/modal';

// Picture
import aboutPic3 from '../../../images/video/pic3.webp';

// Cards 
import Card3 from '../../elements/card/card-3';

// Price 
import Price1 from '../../elements/price/price-1';

// Text
import Text1 from '../../elements/text/text-1';
import Text2 from '../../elements/text/text-2';

export default function YogShow() {

	// modal Video
	const [openModal, setOpenModal] = useState(false);

	// SETTER 
	const [meta, setMeta] = useState('')
	const [banner, setBanner] = useState('')
	const [sec1, setSec1] = useState('')

	// video
	const [videoId, setVideoId] = useState('')
	const [imageVideo, setImageVideo] = useState('https://rest2.yogan.pro/GETPhoto/WDA_0f857f7b-0cef-49c6-a57a-8e821fc65165_Photo1')

	const [sec2, setSec2] = useState('')
	const [card1, setCard1] = useState('')
	const [card2, setCard2] = useState('')
	const [card3, setCard3] = useState('')
	const [sec3, setSec3] = useState('')
	const [price, setPrice] = useState('')
	const [timeLimit, setTimeLimit] = useState('')
	const [engagement, setEngagement] = useState('')
	const [buttunContact, setButtunContact] = useState('')
	const [cgu, setCgu] = useState('')
	const [copyright, setCopyright] = useState('')

	useEffect(() => {
		axios.get(`${config.urlServer}api/yogshow`)
			.then(function (res) {
				setMeta(res.data)
				setBanner(res.data.Composents[0])
				setSec1(res.data.Composents[1])
				setVideoId(res.data.Composents[2].Corps)
				setImageVideo(res.data.Composents[2].Data[0].img)
				setSec2(res.data.Composents[3])

				// Cards
				setCard1(res.data.Composents[3].Data[0])
				setCard2(res.data.Composents[3].Data[1])
				setCard3(res.data.Composents[3].Data[2])

				// Price Card 
				setSec3(res.data.Composents[4])
				setPrice(res.data.Composents[4].Data[0])
				setTimeLimit(res.data.Composents[4].Data[1])
				setEngagement(res.data.Composents[4].Data[2])
				setButtunContact(res.data.Composents[4].Data[3])
				setCgu(res.data.Composents[4].Data[4])
				setCopyright(res.data.Composents[5])
			})
			.catch(err => {
			})
	}, []);

	// CONST 
	const metaData = {
		title: !meta.TitreMeta ? "YogShow - le tout en un pour la gestion accélérée des intermittents du spectacle" : meta.TitreMeta,
		description: !meta.description ? "Un logiciel conçu pour répondre à tous vos besoins en matière de gestion des intermittents du spectacle." : meta.description,
		canonical: !meta.URLCanonical ? "yogshow" : meta.URLCanonical
	}

	const bannerContent = {
		title: !banner.Titre ? "YogShow" : banner.Titre,
		entitled: !banner.Corps ? "YogShow" : banner.Corps
	}

	// Video ID
	const VideoId = {
		id: !videoId ? 'iXdqNmaTQJk' : videoId,
	};
	const videopicture = !imageVideo ? aboutPic3 : imageVideo

	const cards = [
		{
			icons: "flaticon-clipboard-1",
			title: !card1.Titre ? "Pré-Paramétrage Métier" : card1.Titre,
			p: !card1.Corps ? "Un pré-paramétrage complet des métiers et cotisations, pour un outil sur mesure qui répond à vos besoins." : card1.Corps
		},
		{
			icons: "flaticon-growth-1",
			title: !card2.Titre ? "Gestion complète des salaires" : card2.Titre,
			p: !card2.Corps ? "Une gestion complète des salaires : édition de fiche de paie et calcul de la fiche à partir du net." : card2.Corps
		},
		{
			icons: "flaticon-idea",
			title: !card3.Titre ? "Automatisation Des Déclarations Sociales" : card3.Titre,
			p: !card3.Corps ? "Déclaration Automatisée des données sociales unifiées et Nominatives." : card3.Corps
		}
	]

	const section3 = {
		title: !sec3.Titre ? "Prix unique" : sec3.Titre,
		pOffer: !sec3.Corps ? "Toutes les fonctionnalités gratuites pendant le premier mois*, puis" : sec3.Corps,
		price: !price.Corps ? "9,90€" : price.Corps,
		pTime: !timeLimit.Corps ? "par mois (HT)" : timeLimit.Corps,
		pBold: !engagement.Corps ? "sans engagement de durée" : engagement.Corps,
		BTNLib1: !sec3.BTNLib ? "télécharger" : sec3.BTNLib,
		BTNUrl1: !sec3.BTNUrl ? "http://install.yogan.pro/YogShow/" : sec3.BTNUrl,
		BTNLib2: !buttunContact.BTNLib ? "nous contacter" : buttunContact.BTNLib,
		BTNUrl2: !buttunContact.BTNUrl ? "https://yogan.fr/contact" : buttunContact.BTNUrl,
		copyright: !copyright.Corps ? "yogshow est un logiciel édité et réalisé par " : copyright.Corps,
		copyrightLib: !copyright.BTNLib ? "yogan développement" : copyright.BTNLib,
		copyrightUrl: !copyright.BTNUrl ? "https://yogan.fr/" : copyright.BTNUrl,
	}

	const text1 = {
		title: !sec1.Titre ? "Le Tout En Un" : sec1.Titre,
		p: !sec1.Corps ? "Pour la gestion accéléré des intermittents du spectacle." : sec1.Corps,
		alignText: "center",
		h: "capitalize",
		psize: "19px"
	}

	const text2 = {
		title: !sec2.Titre ? "pour un quotidien simplifiée" : sec2.Titre,
		p: !sec2.Corps ? "Un logiciel conçu pour répondre à tous vos besoins en matière de gestion des intermittents du spectacle." : sec2.Corps,
		alignText: "center",
		h: "capitalize",
		psize: "19px"
	}

	const text3 = {
		p: !cgu.Corps ? "*hors demandes spécifiques tel que décrit dans les CGU" : cgu.Corps,
		alignText: "center",
	}

	return (
		<>
			{/* Menu*/}
			<Header />

			{/* Dynamic Meta Tags */}
			<SEO
				title={metaData.title}
				description={metaData.description}
				canonical={metaData.canonical}
			/>

			{/* if openModal == true then we runing Modal component  */}
			{openModal && <Modal setOpenModal={setOpenModal} VideoId={VideoId.id} />}


			<Banner title={bannerContent.title} entitled={bannerContent.entitled} navTitle="Yogan" navLink="https://yogan.fr/" hide="true" />


			{/* <!-- Le tout en un Start --> */}
			<div className="section-area section-sp2 bg-white" id="content-area">
				<div className="container" id="presentation">
					<Text1 text={text1} />
					<div className="row section-sp4 content-center">
						<div className="col-lg-8 wow fadeInUp m-md-b30" data-wow-delay="0.4s">
							<div className="video-media-bx border-retouch">
								<img src={videopicture} alt="Yogan Développement - Yogshow - gestion des intermittents du spectacle" />
								<a className="popup-youtube video-zoom" onClick={() => setOpenModal(true)}><i className="fa fa-play"></i></a>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- Le tout en un End --> */}


			{/* <!-- Pour un quotidien simplifiée Start --> */}
			<div className="section-area section-sp2 bg-gray" id="conception">
				<div className="container">
					<div className="heading-bx text-center">
						<div className="title-head m-b0">
							<Text1 text={text2} />
						</div>
					</div><br />
					<Card3 cards={cards} />
				</div>
			</div>
			{/* <!-- Pour un quotidien simplifiée End --> */}


			{/* <!-- Prix Start --> */}
			<div className="section-area section-sp2" id="prix">
				<div className="container">
					<Price1 section3={section3} />
					<i><Text1 text={text3} /></i><br /><br />
					<Text2 text={section3} />
				</div>
			</div>
			{/* <!-- Prix End --> */}


			<Footer />
		</>
	);
}