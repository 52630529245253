import React from "react";
import { Link } from "react-router-dom";

// Layout
import Footer from "../../layout/footer/footer";

// Element
import SEO from '../../elements/seo/seo';
import Banner from '../../elements/banner/banner';


export default function MentionsLegales() {

	// CONST 
	const metaData = {
		title: "Mentions légales de YogShow",
		description: "Informations légales du site et mentions légales de YogShow.",
		canonical: "mentions-legales"
	}

	const bannerContent = {
		title: "Mentions légales",
	}

	return (
		<>
			{/* Dynamic Meta Tags */}
			<SEO
				title={metaData.title}
				description={metaData.description}
				canonical={metaData.canonical}
			/>

			<Banner title={bannerContent.title} entitled={bannerContent.title} navTitle="Accueil" navLink="/" />

			<div className="section-area section-sp1" id="content-area">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-6">
							<div className='mb-4'>
								<h5>Éditeur</h5>
								<p>
									Le site <Link to="/">www.yogshow.fr</Link> est édité par <a href="https://yogan.fr/" className='link-hover-primary' target="_blank" rel="noreferrer">Yogan Développement</a>, domiciliée au 4 Av. de l'Opéra 75001, Paris (France), SAS au capital social de 13 155 € - SIREN : 490 084 274.
									Yogan est totalement indépendante et reste totalement maître du contenu éditoriale du site.
								</p>
							</div>
							<div className='mb-4'>
								<h5>Hébergement</h5>
								<p>
									Le site <Link to="/" className='link-hover-primary'>www.yogshow.fr</Link> est hébergé par la société Yogan Développement et peut être joint aux contact suivants :
								</p>
								<p>
									Téléphone : <a href="tel:0465230000" className='link-hover-primary'>04 65 23 00 00 </a><br />
									Mail de l’éditeur: <a href="mailto:contact@yogan.fr" className='link-hover-primary'>contact@yogan.fr</a>
								</p>
							</div>
							<div className='mb-4'>
								<h5>Réutilisation des contenus</h5>
								<p>
									L’ensemble du matériel du site <Link to="/" className='link-hover-primary'>www.yogshow.fr</Link>, incluant les textes, les éléments graphiques, les éléments sonores et audiovisuels, les outils de suivi, est la propriété de Yogan, éditeur du site, et est protégé par les lois internationales sur les droits d’auteur.
									Aucun matériel de ce site ne peut être reproduit, modifié, distribué, transmis, republié, affiché ou diffusé sans l’autorisation écrite préalable de Yogan. Pour toute demande relative à l’utilisation du matériel, veuillez écrire à : <a href="mailto:contact@yogan.fr" className='link-hover-primary'>contact@yogan.fr</a>.
								</p>
							</div>
							<div>
								<h5>Données à caractère personnel</h5>
								<p>
									Vos données à caractère personnel sont exclusivement destinées au responsable de traitement et aux autres Utilisateurs du Site et ne seront en aucun cas communiquées à des tiers non autorisés.
									Vous bénéficiez d’un droit d’accès, de rectification et d’opposition des données à caractère personnel vous concernant que vous pouvez exercer en vous adressant par voie postale à Yogan, 4 Av. de l'Opéra 75001, Paris (France).
								</p>
							</div>
							<div className='mb-5'>
								<h5>Non-responsabilité</h5>
								<p>
									Le site <Link to="/" className='link-hover-primary'>www.yogshow.fr</Link> contient des liens permettant un accès direct à d’autres sites internet externes.
									Yogan ne reconnaît aucune responsabilité sur l’information diffusée par ces sites externes, et n’exerce sur celle-ci aucun contrôle éditorial.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<Footer />
		</>
	);
}